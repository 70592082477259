import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import lock from './lock.png';
import css from './TopbarDesktop.module.css';
import HoverMenu from '../HoverMenu/HoverMenu';
import HoverMenuWide from './components/HoverMenuWide/HoverMenuWide';

const authenticationEnabled = process.env.REACT_APP_AUTHENTICATION === 'true';
const searchEnabled = process.env.REACT_APP_SEARCH === 'true';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    type,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [hoverMenuOpened, setHoverMenuOpened] = useState(null);

  const handleScroll = () => {
    if (typeof window !== 'undefined') setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);

      // Clean up
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (scrollPosition > 0 && type === 'floating') return null;

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const isHouseBuilder = currentUser?.attributes?.profile?.publicData?.userType === 'houseBuilder';
  const isArchitect = currentUser?.attributes?.profile?.publicData?.userType === 'architect';
  const isCustomer = currentUser?.attributes?.profile?.publicData?.userType === 'customer';

  const classes =
    type === 'floating'
      ? classNames(rootClassName || css.root, className)
      : classNames(rootClassName || css.staticRoot, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {isCustomer ? (
          <MenuItem key="ManageListingsPage"></MenuItem>
        ) : (
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>
        )}

        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="MoodMapPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('MoodMapPage'))}
            name="MoodMapPage"
          >
            <span className={css.menuItemBorder} />
            <span>Mood board</span>
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name={'SignupPage'} className={css.signupLink}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M15.6275 10.9692C17.0355 9.96584 17.9524 8.34208 17.9524 6.51282C17.9524 3.47305 15.4212 1 12.31 1C9.19876 1 6.66757 3.47305 6.66757 6.51282C6.66757 8.34208 7.58445 9.96584 8.99254 10.9692C5.49229 12.2778 3 15.5936 3 19.468C3 21.1398 4.39215 22.5 6.10333 22.5H18.5167C20.2279 22.5 21.62 21.1398 21.62 19.468C21.62 15.5936 19.1277 12.2778 15.6275 10.9692ZM8.36032 6.51282C8.36032 4.38499 10.1321 2.65386 12.31 2.65386C14.4879 2.65386 16.2597 4.38499 16.2597 6.51282C16.2597 8.64065 14.4879 10.3718 12.31 10.3718C10.1321 10.3718 8.36032 8.64065 8.36032 6.51282ZM18.5167 20.8461H6.10333C5.32553 20.8461 4.69275 20.2279 4.69275 19.4679C4.69275 15.3642 8.10979 12.0256 12.31 12.0256C16.5103 12.0256 19.9273 15.3641 19.9273 19.4679C19.9273 20.2279 19.2945 20.8461 18.5167 20.8461Z"
          fill="white"
        />
      </svg>
      <span className={css.signup}>Create account</span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name={'LoginPage'} className={css.loginLink}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M15.6275 10.9692C17.0355 9.96584 17.9524 8.34208 17.9524 6.51282C17.9524 3.47305 15.4212 1 12.31 1C9.19876 1 6.66757 3.47305 6.66757 6.51282C6.66757 8.34208 7.58445 9.96584 8.99254 10.9692C5.49229 12.2778 3 15.5936 3 19.468C3 21.1398 4.39215 22.5 6.10333 22.5H18.5167C20.2279 22.5 21.62 21.1398 21.62 19.468C21.62 15.5936 19.1277 12.2778 15.6275 10.9692ZM8.36032 6.51282C8.36032 4.38499 10.1321 2.65386 12.31 2.65386C14.4879 2.65386 16.2597 4.38499 16.2597 6.51282C16.2597 8.64065 14.4879 10.3718 12.31 10.3718C10.1321 10.3718 8.36032 8.64065 8.36032 6.51282ZM18.5167 20.8461H6.10333C5.32553 20.8461 4.69275 20.2279 4.69275 19.4679C4.69275 15.3642 8.10979 12.0256 12.31 12.0256C16.5103 12.0256 19.9273 15.3641 19.9273 19.4679C19.9273 20.2279 19.2945 20.8461 18.5167 20.8461Z"
          fill="white"
        />
      </svg>
      <span className={css.signup}>Log in</span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />

      <div className={css.actionButtonsSection}>
        {/* <NamedLink name="LandingPage" className={css.pageLink}>
          Home
        </NamedLink> */}
        <HoverMenu
          label={'Home designs'}
          menuLinks={[
            {
              label: 'View home designs',
              name: 'SearchPage',
            },
            isCustomer
              ? null
              : {
                  label: 'Upload designs',
                  name:
                    (isHouseBuilder || isArchitect) && isAuthenticated
                      ? 'NewListingPage'
                      : 'SignupPage',
                },
          ]}
          hoverMenuOpened={false}
          setHoverMenuOpened={setHoverMenuOpened}
        />

        <HoverMenu
          label={'How It Works'}
          menuLinks={[
            {
              label: 'About us',
              name: 'AboutUsPage',
            },
            {
              label: 'How it works',
              name: 'HowItWorksPage',
            },
            {
              label: 'Discover MMC',
              name: 'MMCPage',
            },
            {
              label: 'Blogs',
              name: 'BlogPage',
            },
          ]}
          hoverMenuOpened={false}
          setHoverMenuOpened={setHoverMenuOpened}
        />

        <HoverMenu
          label={<span className={css.menuLabelWrapper}>Trusted Suppliers</span>}
          menuLinks={[
            {
              label: 'For MMC companies',
              name: 'PricingPage',
              hash: 'pricingMMC',
            },
            {
              label: 'For Customers',
              name: 'PricingPage',
              hash: 'pricingCustomers',
            },
            // {
            //   label: 'For Partnerships',
            //   externalPage: 'https://calendly.com/themodernbuilder/30min',
            //   name: 'LandingPage',
            // },
            {
              label: 'Partnerships',
              name: 'PricingPage',
              hash: 'partnerships',
            },
            {
              label: 'FAQ',
              name: 'PricingPage',
              hash: 'faq',
            },
          ]}
          hoverMenuOpened={false}
          setHoverMenuOpened={setHoverMenuOpened}
        />
      </div>

      {(isHouseBuilder || isArchitect) && (
        <NamedLink className={css.createListingLink} name="NewListingPage">
          <span className={css.createListing}>Add new</span>
        </NamedLink>
      )}
      {inboxLink}
      {profileMenu}
      {signupLink}
      {loginLink}
      {hoverMenuOpened && (
        <HoverMenuWide
          isAuthenticated={isAuthenticatedOrJustHydrated}
          setHoverMenuOpened={setHoverMenuOpened}
        />
      )}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
