import React from 'react';
import cover from './cover.jpg';
import css from './QuizSection.module.css';

const QuizSection = () => {
  return (
    <div className={css.wrapper}>
      <img src={cover} className={css.cover} />

      <div className={css.content}>
        <h2>Not sure how to start your construction project?</h2>

        <p>Let us help you assess your readiness.</p>
        <a
          className={css.ctaButton}
          href="https://themodernbuilder.scoreapp.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Try Now
        </a>
      </div>
    </div>
  );
};

export default QuizSection;
