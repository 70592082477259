import React, { useEffect } from 'react';
import css from './CustomLandingPage.module.css';
import { LayoutSingleColumn, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import HeroSection from './components/HeroSection/HeroSection';
import HowItWorksSection from './components/HowItWorksSection/HowItWorksSection';
import DiscoverSection from './components/DiscoverSection/DiscoverSection';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PromotedListings from './components/PromotedListings/PromotedListings';
import BannerSection2 from './components/BannerSection2/BannerSection2';
import CardsSection from './components/CardsSection/CardsSection';
import QuestionSection from './components/QuestionSection/QuestionSection';
import Popup from '../../components/Popup/Popup';
import PopupContent from './components/PopupContent/PopupContent';
import LogosSection from './components/LogosSection/LogosSection';
import Step1Section from './components/Step1Section/Step1Section';
import Step2Section from './components/Step2Section/Step2Section';
import Step3Section from './components/Step3Section/Step3Section';
import QuizSection from './components/QuizSection/QuizSection';

const CustomLandingPageComponent = props => {
  const { currentUser, location } = props;
  return (
    <Page
      title={'Self-Build The Easy Way - The Modern Builder'}
      description={
        'The Modern Builder guides you through the self-build process, putting you in control at every step of your project, so your dream home can become a reality.'
      }
      scrollingDisabled={false}
    >
      <LayoutSingleColumn
        rootClassName={css.pageRoot}
        topbar={<TopbarContainer currentPage="LandingPage" type="floating" overlay={true} />}
        footer={<FooterContainer />}
      >
        {/* {!currentUser?.id?.uuid && (
          <Popup
            delay={3000}
            content={<PopupContent location={location} />}
            closeStyle={css.close}
          />
        )} */}
        <BannerSection2 />
        <LogosSection />
        <div className={css.graySeparator}></div>
        <Step1Section />
        <div className={css.graySeparator}></div>
        <Step2Section />
        <div className={css.graySeparator}></div>
        <Step3Section isLoggedIn={currentUser?.id?.uuid} />
        <div className={css.graySeparator}></div>
        <QuizSection />
        <PromotedListings />
        <HeroSection />
        <HowItWorksSection />
        <DiscoverSection />
        <QuestionSection />
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const CustomLandingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CustomLandingPageComponent);

export default CustomLandingPage;
