import React, { useState } from 'react';
import css from './PromotedListings.module.css';
import { useConfiguration } from '../../../../context/configurationContext';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isScrollingDisabled, manageDisableScrolling } from '../../../../ducks/ui.duck';
import { getListingsById } from '../../../../ducks/marketplaceData.duck';
import { Button, ListingCard } from '../../../../components';
import { arrowLeft, arrowRight } from './utils';
import DiscoverBanner from '../../../../components/DiscoverBanner/DiscoverBanner';

const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

const PromotedListingsComponent = props => {
  const [focusedListing, setFocusedListing] = useState(0);
  const { listings } = props;
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  if (!Array.isArray(listings) || listings.lenght < 0) return;

  const promotedListings = listings?.slice(0, 16);
  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  if (isMobile) {
    return (
      <div className={css.wrapper}>
        <DiscoverBanner />
        <div className={css.textBox}>Explore New Home Design Ideas</div>

        {promotedListings[focusedListing]?.id?.uuid && (
          <ListingCard
            className={css.listingCard}
            key={promotedListings[focusedListing].id.uuid}
            listing={promotedListings[focusedListing]}
            renderSizes={cardRenderSizes(false)}
            setActiveListing={() => {}}
            showLikeDislikeButton={true}
          />
        )}

        <div className={css.buttonWrapper}>
          <div
            onClick={() => {
              if (focusedListing > 0) setFocusedListing(focusedListing - 1);
            }}
          >
            {arrowLeft}
          </div>
          <Button className={css.seeMoreButton} onClick={() => history.push('/home_designs')}>
            Visit Marketplace
          </Button>
          <div
            onClick={() => {
              if (focusedListing < promotedListings.length - 1)
                setFocusedListing(focusedListing + 1);
            }}
          >
            {arrowRight}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={css.wrapper}>
      <DiscoverBanner />
      <div className={css.textBox}>Explore New Home Design Ideas</div>
      <div className={css.listingCards}>
        {promotedListings.map(l => {
          return (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes(false)}
              setActiveListing={() => {}}
              showLikeDislikeButton={true}
            />
          );
        })}
      </div>

      <div className={css.buttonWrapper}>
        <Button className={css.seeMoreButton} onClick={() => history.push('/home_designs')}>
          Visit Marketplace
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
  } = state.SearchPage;
  const listings = getListingsById(state, currentPageResultIds);

  return {
    listings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const PromotedListings = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PromotedListingsComponent);

export default PromotedListings;
