import React, { useEffect, useState, useRef } from 'react';
import close from './close.png';
import cover from './cover.jpg';
import arrow from './arrow.png';
import css from './DiscoverBanner.module.css';

const DiscoverBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const bannerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            setIsVisible(true);
          }, 500); // 0.5 seconds delay
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        observer.unobserve(bannerRef.current);
      }
    };
  }, []);

  if (isClosed) return null;
  return (
    <div ref={bannerRef} className={`${css.wrapper} ${isVisible ? css.visible : css.hidden}`}>
      <div className={css.banner}>
        <div className={css.closeWrapper}>
          <img src={close} className={css.close} onClick={() => setIsClosed(true)} />
        </div>
        <img className={css.cover} src={cover} />
        <p>Discover Your Construction Readiness!</p>
        <a href="https://themodernbuilder.scoreapp.com" target="_blank" className={css.cta}>
          Begin the Quiz <img src={arrow} className={css.arrow} />
        </a>
      </div>
    </div>
  );
};

export default DiscoverBanner;
