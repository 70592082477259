import React, { useEffect, useRef } from 'react';
import css from './HoverMenuWide.module.css';
import homeDesigns from './images/homeDesigns.png';
import uploadDesigns from './images/uploadDesigns.png';
import aboutUs from './images/aboutUs.png';
import howItWorks from './images/howItWorks.png';
import discoverMMC from './images/discoverMMC.png';
import blogs from './images/blogs.png';
import mmcCompanies from './images/mmcCompanies.png';
import forCustomers from './images/forCustomers.png';
import partnerships from './images/partnerships.png';
import faqs from './images/faqs.png';
import playCircle from './images/playCircle.png';
import speakIcon from './images/speakIcon.png';
import newsletter from './images/newsletter.png';

const HoverMenuWide = ({ setHoverMenuOpened, isAuthenticated }) => {
  const menuContentRef = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleOutsideClick = event => {
        if (menuContentRef.current && !menuContentRef.current.contains(event.target)) {
          setHoverMenuOpened(null);
        }
      };

      document.addEventListener('click', handleOutsideClick);

      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }
  }, []);

  const homeDesignItems = [
    {
      icon: homeDesigns,
      label: 'View home designs',
      sublabel: 'View Marketplace',
      href: '/home_designs',
    },
    {
      icon: uploadDesigns,
      label: 'Upload designs',
      sublabel: 'Upload designs to the Marketplace',
      href: '/l/draft/00000000-0000-0000-0000-000000000000/new/details',
    },
  ];

  const howItWorksItems = [
    {
      icon: howItWorks,
      label: 'How it works',
      sublabel: 'How the platform works',
      href: '/how-it-works',
    },
    {
      icon: discoverMMC,
      label: 'Offsite Construction',
      sublabel: ' Types of Offsite Construction',
      href: '/mmc',
    },
    {
      icon: forCustomers,
      label: 'Newsletters',
      sublabel: 'Stay updated with our latest news',
      href: '/community',
    },
    // ##############
  ];

  const membershipsItems = [
    {
      icon: newsletter,
      label: 'Trusted Suppliers',
      sublabel: 'Discover our reliable partners',
      href: '/trusted-suppliers',
    },
    {
      icon: aboutUs,
      label: 'About us',
      sublabel: 'Find out about the team',
      href: '/about',
    },
    {
      icon: blogs,
      label: 'Blogs',
      sublabel: 'Some articles to help your research',
      href: '/self-build-blogs',
    },
  ];

  return (
    <div className={css.wrapper} ref={menuContentRef}>
      <div className={css.columnsWrapper}>
        <div className={css.column}>
          {/* <div className={css.columnTitle}>Home designs</div> */}
          {homeDesignItems.map(i => {
            return (
              <a className={css.columnItem} href={i.href}>
                <div className={css.iconWrapper}>
                  <img src={i.icon} className={css.icon} />
                </div>

                <div className={css.textWrapper}>
                  <p>{i.label}</p>
                  <span>{i.sublabel}</span>
                </div>
              </a>
            );
          })}
        </div>

        <div className={css.column}>
          {/* <div className={css.columnTitle}>How It Works</div> */}
          {howItWorksItems.map(i => {
            return (
              <a className={css.columnItem} href={i.href}>
                <div className={css.iconWrapper}>
                  <img src={i.icon} className={css.icon} />
                </div>

                <div className={css.textWrapper}>
                  <p>{i.label}</p>
                  <span>{i.sublabel}</span>
                </div>
              </a>
            );
          })}
        </div>

        <div className={css.column}>
          {/* <div className={css.columnTitle}>Trusted Suppliers</div> */}
          {membershipsItems.map(i => {
            return (
              <a className={css.columnItem} href={i.href}>
                <div className={css.iconWrapper}>
                  <img src={i.icon} className={css.icon} />
                </div>

                <div className={css.textWrapper}>
                  <p>{i.label}</p>
                  <span>{i.sublabel}</span>
                </div>
              </a>
            );
          })}
        </div>
      </div>
      <div className={css.ctaWrapper}>
        <div className={css.login}>
          {!isAuthenticated && (
            <p className={css.loginText}>
              Ready to get started?
              <a href={'/signup'}>Sign up for free</a>
            </p>
          )}
        </div>
        <div className={css.buttonsWrapper}>
          <a
            className={css.link}
            href={'https://www.youtube.com/watch?v=EyHIIpJaOeA'}
            target={'_blank'}
          >
            <img src={playCircle} />
            <span>Watch demo</span>
          </a>

          <a
            className={css.link}
            href={'https://calendly.com/themodernbuilder/discovery'}
            target={'_blank'}
          >
            <img src={speakIcon} />
            <span>Speak to the team</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HoverMenuWide;
