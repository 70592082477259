import React from 'react';
import css from './CustomTopbarMobileMenu.module.css';
import NamedLink from '../../NamedLink/NamedLink';

const CustomTopbarMobileMenu = ({ handleClose, currentUser }) => {
  const isHouseBuilder = currentUser?.attributes?.profile?.publicData?.userType === 'houseBuilder';
  const isArchitect = currentUser?.attributes?.profile?.publicData?.userType === 'architect';
  const isCustomer = currentUser?.attributes?.profile?.publicData?.userType === 'customer';
  const isAuthenticated = currentUser?.id?.uuid;
  return (
    <div className={css.wrapper}>
      <div className={css.exitButton} onClick={handleClose}>
        X
      </div>
      <NamedLink name="SearchPage" className={css.pageLink}>
        <h1>View home designs</h1>
      </NamedLink>

      {(isHouseBuilder || isArchitect) && isAuthenticated ? (
        <NamedLink name="NewListingPage" className={css.pageLink}>
          <h1>Upload designs</h1>
        </NamedLink>
      ) : (
        !isAuthenticated && (
          <NamedLink name="SignupPage" className={css.pageLink}>
            <h1>Upload designs</h1>
          </NamedLink>
        )
      )}

      <NamedLink name="HowItWorksPage" className={css.pageLink}>
        <h1>How it works</h1>
      </NamedLink>

      <NamedLink name="MMCPage" className={css.pageLink}>
        <h1>Offsite construction</h1>
      </NamedLink>

      <NamedLink name="CommunityPage" className={css.pageLink}>
        <h1>Newsletters</h1>
      </NamedLink>

      <NamedLink name="TrustedSuppliersPage" className={css.pageLink}>
        <h1>Trusted Suppliers</h1>
      </NamedLink>

      <NamedLink name="AboutUsPage" className={css.pageLink}>
        <h1>About us</h1>
      </NamedLink>

      <NamedLink name="BlogPage" className={css.pageLink}>
        <h1>Blogs</h1>
      </NamedLink>
      {/* <NamedLink name="PricingPage" className={css.pageLink}>
        <h1>For MMC</h1>
      </NamedLink> */}

      {/* <a
        href="https://calendly.com/themodernbuilder/30min"
        target="_blank"
        className={css.pageLink}
      >
        <h1>Partnerships</h1>
      </a> */}
    </div>
  );
};

export default CustomTopbarMobileMenu;
