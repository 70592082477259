import React, { useEffect, useRef, useState } from 'react';
import coverMobile from './coverMobile.jpg';
import coverDesktop from './coverDesktop.jpg';
import closeDesktop from './closeDesktop.png';
import closeMobile from './closeMobile.png';
import css from './PopupQuiz.module.css';

const isMobile = typeof window !== 'undefined' && window.innerWidth < 1025;
const secondsBeforShow = 10;

const PopupQuiz = () => {
  const [show, setShow] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const popupDismissed = sessionStorage.getItem('popupDismissed');
    const entryTime = sessionStorage.getItem('entryTime');

    if (!popupDismissed) {
      const now = Date.now();

      if (!entryTime) {
        sessionStorage.setItem('entryTime', now);
      }

      const timeElapsed = now - (entryTime ? parseInt(entryTime, 10) : now);
      const delay = Math.max(secondsBeforShow * 1000 - timeElapsed, 0); // Calculate remaining time to show popup

      const timer = setTimeout(() => {
        setShow(true);
      }, delay);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setShow(false);
    sessionStorage.setItem('popupDismissed', 'true');
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleClickOutside = event => {
        if (contentRef.current && !contentRef.current.contains(event.target)) {
          handleClose();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  if (!show) return null;

  return (
    <div className={css.wrapper}>
      <div className={css.content} ref={contentRef}>
        <img src={coverMobile} className={css.image} alt="Popup Cover" />
        <div className={css.textWrapper}>
          <div className={css.close}>
            <img
              src={isMobile ? closeMobile : closeDesktop}
              className={css.closeIcon}
              alt="Close Button"
              onClick={handleClose}
            />
          </div>
          <p>Thanks for visiting us today!</p>
          <h2>
            Take our short quiz to help you discover your readiness to embark on your construction
            journey.
          </h2>
          <p>
            Your results will help us fill in any gaps in your knowledge and tailor how we can help
            you.
          </p>
          <a
            className={css.ctaButton}
            href="https://themodernbuilder.scoreapp.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Start now
          </a>
        </div>
      </div>
    </div>
  );
};

export default PopupQuiz;
