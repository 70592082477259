import React from 'react';
import cover from './cover.jpg';
import arrow from './arrow.png';
import css from './BottomPageBanner.module.css';

const BottomPageBanner = () => {
  return (
    <div className={css.wrapper}>
      <img src={cover} className={css.cover} />
      <div className={css.content}>
        <h2>Unsure about where to begin with your Construction Project?</h2>
        <p>Assess Your Readiness Now by filling our quiz!</p>
        <a href="https://themodernbuilder.scoreapp.com" target="_blank" className={css.cta}>
          Start Now <img src={arrow} className={css.arrow} />
        </a>
      </div>
    </div>
  );
};

export default BottomPageBanner;
