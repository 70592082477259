import React from 'react';
import css from './OrangeBanner.module.css';

const OrangeBanner = ({ type }) => {
  const isFloating = type === 'floating';
  return (
    <div className={`${css.wrapper} ${!isFloating && css.relativeClass}`}>
      Take our quiz to discover how ready you are to start your construction project!
      <a href="https://themodernbuilder.scoreapp.com" target="_blank">
        Start Now!
      </a>
    </div>
  );
};

export default OrangeBanner;
